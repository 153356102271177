.progress-bar {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar__fill {
    height: 100%;
    transition: width 0.3s ease-in-out;
  }
  
  .progress-bar__text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    z-index: 1;
    pointer-events: none; /* Ensure text is not interactive */
  }
  