.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color:#DDD8C4;
    width: 30rem;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;


  }
  
  .card img {
    margin-top: -20%;
    margin-bottom: -20%;
    width: 100%;
    border-radius: 20px;
  }
  
  .card h2 {
 
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    color: #333;
    margin: 0.5em 0;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #e2e2e2;
    padding-bottom: 0.3em;

  
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  