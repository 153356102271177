.parent {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
 
  }
  
  .child {
    flex: 1; /* Equal columns for the first row */
    border: 1px solid #ddd; /* Add borders for visual separation */
    padding: 10px;
    box-sizing: border-box; /* Include padding in the element's width */
    margin: 5px; /* Add margin between items */
    flex-direction: column;
    display: flex;
   
 
  }
  
  .single-child {
    flex: 1; /* Full width for the second row */
    border: 1px solid #ddd; /* Add borders for visual separation */
    padding: 10px;
    box-sizing: border-box; /* Include padding in the element's width */
   /* Add margin between items */
   

  }