

.App {
  text-align: center;
  position: relative; /* Make sure the container is positioned relatively */
}

/* Add a pseudo-element to overlay the background image */
.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  opacity: 0.2; /* Adjust the opacity as needed */
  z-index: -1; /* Place the pseudo-element behind the content */
  background-size: cover;
  background-position: center;

}
/* Background for Home page */
.home-background::before {
  content: ''; /* Required for pseudo-elements */
  position: fixed; /* Sticks the background to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-image: url('./background.png');
  background-size: cover; /* Ensures full coverage */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating */
  z-index: -1; /* Sends the background behind the content */
}

/* Background for About page */
.port-background::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('./backgroundPORTFOLIO.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

/* Background for Calendar page */
.cal-background::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('./backgroundCal.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
